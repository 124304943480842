<template>
  <div class="mt-1">
    <b-overlay center :show="isLoading" rounded="sm">
      <div v-if="!isLoading">
        <div v-if="ok">
          <validation-observer
            ref="observer"
            v-slot="{ invalid, handleSubmit }"
          >
            <b-form @submit.prevent="">
              <b-card header="Dati Generali" header-tag="header">
                <b-card-text>
                  <b-row>
                    <div class="form-group col-md-3">
                      <base-input
                        :name="beForm[rep].code.label"
                        vid="code"
                        :label="getDictionary('code', 'mandate_code')"
                        v-model="form[rep].code"
                        :rules="getRules('code')"
                        maxlength="4"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-input
                        :name="beForm[rep].title.label"
                        vid="title"
                        :label="getDictionary('title', 'mandate_code')"
                        v-model="form[rep].title"
                        :rules="getRules('title')"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-select
                        :name="beForm[rep].insurer_id.label"
                        vid="insurer_id"
                        :label="getDictionary('insurer')"
                        v-model="form[rep].insurer_id"
                        :options="companies"
                        :rules="getRules('insurer_id')"
                        :disabled="true"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-select
                        :name="beForm[rep].is_expired.label"
                        vid="is_expired"
                        :label="getDictionary('is_expired', 'mandate_code')"
                        v-model="form[rep].is_expired"
                        :options="beForm[rep].is_expired.options"
                        :rules="getRules('is_expired')"
                      />
                    </div>
                  </b-row>
                </b-card-text>
              </b-card>

              <div class="py-2 mb-4">
                <b-button
                  @click="handleSubmit(onSubmit)"
                  type="button"
                  :disabled="invalid"
                  variant="lisaweb"
                  size="sm"
                >
                  Salva
                </b-button>
                <b-button @click="resetForm()" variant="lisaweb" size="sm"
                  >Reset</b-button
                >
                <b-button
                  @click="$router.back()"
                  variant="lisaweb"
                  size="sm"
                  class="float-right"
                  ><b-icon-chevron-double-left
                    font-scale="0.9"
                  ></b-icon-chevron-double-left
                  >Torna indietro</b-button
                >
              </div>
            </b-form>
          </validation-observer>
        </div>
        <div v-else>
          {{ errorMessage }}
        </div>
      </div>

      <template #overlay>
        <div class="text-center mt-5">
          <base-icon name="loading" width="35" height="35" />
          <p id="cancel-label">Operazione in corso...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import ShortcutMixin from "@/mixins/ShortcutMixin";
import FormMixin from "@/mixins/FormMixin";
import BaseIcon from "@/components/BaseIcon";
import BaseInput from "@/components/form/BaseInput";
import BaseSelect from "@/components/form/BaseSelect";
import { mapGetters } from "vuex";

export default {
  mixins: [FormMixin, ShortcutMixin],
  data() {
    return {
      repository: "mandate_code",
      id: this.$route.params.id,
      companies: [],
      form: {
        mandate_code: {
          code: null,
          title: null,
          insurer_id: null,
          is_expired: null,
        },
      },
    };
  },
  components: {
    BaseIcon,
    BaseInput,
    BaseSelect,
  },
  methods: {
    onSubmit() {
      this.isLoading = true;
      this.update(this.repository, this.id)
        .then(() => {
          this.$store.dispatch("auth/mandate_codes");
          this.$showSnackbar({
            preset: "success",
            text: `Azione Completata: Mandato Modificato`,
          });
          this.shortcut(
            "module.PROF",
            null,
            "#MandateCodes",
            "filterMandateCode"
          );
          this.isLoading = false;
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
          this.isLoading = false;
        });
    },
    ...mapGetters("auth", {
      getInsurers: "insurers",
    }),
  },
  created() {
    this.isLoading = true;
    this.fetchEditForm(this.repository, this.id).then(() => {
      this.companies = this.getInsurers();
      this.isLoading = false;
    });
  },
  computed: {
    rep() {
      return this.repository;
    },
    prdct() {
      return this.products;
    },
  },
};
</script>
